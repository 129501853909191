// import "./styles.css";
// import { MyComponent } from "./components/MyComponent";
import { RingsAnimation } from "./components/RingsAnimation";

export default function App() {
  return (
    <div className="App" style={{height: "100vh"}}>
      {/* <MyComponent/> */}
      <RingsAnimation />
    </div>
  );
}
